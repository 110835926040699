import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import configMenuActions from 'actions/configMenuActions';
import { MerchantClient } from '../../util';
import {withHistory} from 'SimpleHistory';
import {connect} from 'react-redux';
import {APP_SDK_ENV} from 'config/constants';
import {formatUrl} from '../../util';

let button;

const TokenButtonV2 = ({
    amount,
    currency,
    destination,
    devKey,
    extra,
    option,
    source,
    webAppEnabled,
    bulkTransfers,
    sourceCountry,
    betaVersionEnabled,
    memberType,
    clientName,
    cafEnabled,
    remittanceReference,
    credentials,
    text,
    onError,
    setProviderDetails,
    history,
}) => {
    let buttonRef = useRef(null);

    const addPayButton = () => {
        if (button) {
            button.destroy();
        }

        const token = new window.TokenApp({
            env: APP_SDK_ENV,
        });

        const tokenController = token.createController({
            onSuccess: async data => { // Success Callback
                try {
                    const res = await MerchantClient.instructPayment(data);
                    res.data.providerDetails && setProviderDetails(res.data.providerDetails);
                    res.data.status === 'SUFFICIENT_FUNDS' ?
                        history.push(formatUrl(clientName, '/cart/fundsConfirmed'))
                        : res.data.status === 'INSUFFICIENT_FUNDS' ?
                            history.push(formatUrl(clientName, '/cart/insufficientFunds'))
                            : history.push(formatUrl(clientName, '/cart/thankyou'));

                } catch (error) {
                    onError(error.response.data);
                    // error screen
                    history.push(formatUrl(clientName, '/cart/error'));
                }
            },
            onError: error => { // Failure Callback
                console.log(error);
                onError(error);
                history.push(formatUrl(clientName, '/cart/error'));
                throw error;
            },
        });

        // create button instance
        button = tokenController.createTokenButton(buttonRef, {
            label: text,
            hideLogo: clientName === 'southside' ? true : false,
        });

        button.setButtonClickHandler(
            async (done, errorCb) => { // optional async request for popup
                try {
                    const { data: tokenRequestUrl } = await MerchantClient.getTokenRequestUrl(
                        amount, currency, destination, devKey, extra, option, source,
                        webAppEnabled, bulkTransfers, sourceCountry, betaVersionEnabled, memberType,
                        clientName, cafEnabled, remittanceReference, credentials, true,
                    );
                    done(tokenRequestUrl);
                } catch (error) {
                    // execute error callback
                    if (error.response.data.message)
                        error.message = error.response.data.message;
                    else
                        error.message = 'Error fetching Token Request URL';
                    errorCb(error);
                }
            },
            redirect => { // redirect
                redirect('/getTokenRequestUrl', {
                    amount,
                    currency,
                    destination,
                    devKey,
                    extra,
                    option,
                    source,
                    webAppEnabled,
                    bulkTransfers,
                    sourceCountry,
                    betaVersionEnabled,
                    memberType,
                    clientName,
                    cafEnabled,
                    remittanceReference,
                    credentials,
                    webappV2Enabled: true,
                });
            },
        );
    };

    useEffect(() => {
        addPayButton();
    }, [JSON.stringify({
        amount,
        currency,
        destination,
        devKey,
        extra,
        option,
        source,
        webAppEnabled,
        bulkTransfers,
        sourceCountry,
        betaVersionEnabled,
        memberType,
        clientName,
        cafEnabled,
        remittanceReference,
        onError,
        credentials})]);

    return (
        <div id='myTokenButton' className="TokenEnablerPlaceholder" ref={ref => {
            buttonRef = ref;
        }}>
        </div>
    );
};

TokenButtonV2.propTypes = {
    onClick: PropTypes.func,
    onError: PropTypes.func,
    text: PropTypes.string,
    amount: PropTypes.number,
    currency: PropTypes.string,
    webAppEnabled: PropTypes.bool,
    destination: PropTypes.object,
    devKey: PropTypes.string,
    isReady: PropTypes.bool,
    history: PropTypes.object,
    extra: PropTypes.object,
    option: PropTypes.object,
    source: PropTypes.object,
    bulkTransfers: PropTypes.array,
    sourceCountry: PropTypes.string,
    betaVersionEnabled: PropTypes.bool,
    memberType: PropTypes.string,
    url: PropTypes.string,
    clientName: PropTypes.string,
    cafEnabled: PropTypes.bool,
    credentials: PropTypes.object,
    setProviderDetails: PropTypes.func,
    remittanceReference: PropTypes.string,
    webappInIframe: PropTypes.bool,
};

TokenButtonV2.defaultProps = {
    onClick: () => {
    },
    onError: () => {
    },
    text: 'Enable Token',
};

const mapStateToProps = state => {
    return {
        clientName: state.clientName,
    };
};

const mapDispatchToProps = {
    setProviderDetails: configMenuActions.setProviderDetails,
};

export default withHistory(connect(mapStateToProps, mapDispatchToProps)(TokenButtonV2));
